import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[(_vm.errorCargaMasiva)?_c(VCardText,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('h3',[_vm._v("Se ha producido un Error. Intente una vez más.")])]),_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","md":"12","sm":"12"}},[_c(VBtn,{attrs:{"depressed":"","color":"red","text":""},on:{"click":function($event){_vm.cargaMasivaInventario = false;
            _vm.ficheroCarga = null;
            _vm.cargaCargaCompleta = false;
            _vm.loadingCargaMasiva = false;
            _vm.errorCargaMasiva = false;
            _vm.selectedUpdateFile = null;}}},[_vm._v("Aceptar")])],1)],1):_vm._e(),(_vm.cargaCargaCompleta)?_c(VCardText,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","md":"12","sm":"12"}},[(_vm.cargaLink === '')?_c('h3',[_vm._v(" Carga Realizada Satisfactoriamente ")]):_c('div',[_c('h3',[_vm._v("Carga Realizada Parcialmente")]),_c('a',{attrs:{"href":'/api/Soluciones/ReporteErroresSolucion/' + _vm.cargaLink + '?access_token=' + _vm.token,"target":"_blank"}},[_vm._v("Archivo de Reporte")])])]),_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","md":"12","sm":"12"}},[_c(VBtn,{attrs:{"depressed":"","color":"green","text":""},on:{"click":function($event){_vm.cargaMasivaInventario = false;
            _vm.ficheroCarga = null;
            _vm.cargaCargaCompleta = false;
            _vm.loadingCargaMasiva = false;
            _vm.errorCargaMasiva = false;
            _vm.selectedUpdateFile = null;}}},[_vm._v("Aceptar")])],1)],1):_vm._e(),(_vm.loadingCargaMasiva && !_vm.errorCargaMasiva)?_c(VCardText,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('h3',[_vm._v("Cargando")])]),_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","md":"12","sm":"12"}},[_c(VProgressCircular,{attrs:{"size":75,"color":"green","indeterminate":""}})],1)],1):_vm._e(),(!_vm.loadingCargaMasiva && !_vm.cargaCargaCompleta && !_vm.errorCargaMasiva)?_c(VCardText,{staticClass:"text-center"},[_c('p',{staticClass:"mb-0 text-h6 text--secondary text-left"},[_vm._v(" Actualización Masiva de Identifica de Activos ")]),_c(VFileInput,{attrs:{"label":"Fichero Carga .xlsx","accept":".xlsx","counter":"","show-size":""},model:{value:(_vm.selectedUpdateFile),callback:function ($$v) {_vm.selectedUpdateFile=$$v},expression:"selectedUpdateFile"}}),_c('a',{on:{"click":_vm.descargarActualizacionMasiva}},[_c(VIcon,{attrs:{"color":"blue"}},[_vm._v("mdi-download")]),_vm._v("Descargar Plantilla de Actualizacion Masiva de Identificador Activos ")],1),_c('br'),_c('br'),_c(VRow,[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"my-2 mb-4",attrs:{"block":"","color":"blue","outlined":""},on:{"click":_vm.cargaMasivaSolucionesPost}},[_vm._v("Cargar archivo de Actualizacion Masiva de Identificador Activos")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }