<template>
    <v-card flat>
        <v-card-text v-if="errorCargaMasiva">
            <v-col cols="12" md="12" sm="12" class="text-center">
                <h3>Se ha producido un Error. Intente una vez más.</h3>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="text-center">
                <v-btn depressed color="red" text @click="
                cargaMasivaInventario = false;
                ficheroCarga = null;
                cargaCargaCompleta = false;
                loadingCargaMasiva = false;
                errorCargaMasiva = false;
                selectedUpdateFile = null;">Aceptar</v-btn>
            </v-col>
        </v-card-text>
        <v-card-text v-if="cargaCargaCompleta">
            <v-col cols="12" md="12" sm="12" class="text-center">
                <h3 v-if="cargaLink === ''">
                    Carga Realizada Satisfactoriamente
                </h3>
                <div v-else>
                    <h3>Carga Realizada Parcialmente</h3>
                    <a :href="'/api/Soluciones/ReporteErroresSolucion/' + cargaLink + '?access_token=' + token" target="_blank">Archivo de Reporte</a>
                </div>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="text-center">
                <v-btn depressed color="green" text @click="
                cargaMasivaInventario = false;
                ficheroCarga = null;
                cargaCargaCompleta = false;
                loadingCargaMasiva = false;
                errorCargaMasiva = false;
                selectedUpdateFile = null;">Aceptar</v-btn>
            </v-col>
        </v-card-text>
        <v-card-text v-if="loadingCargaMasiva && !errorCargaMasiva">
            <v-col cols="12" md="12" sm="12" class="text-center">
                <h3>Cargando</h3>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="text-center">
                <v-progress-circular :size="75" color="green" indeterminate></v-progress-circular>
            </v-col>
        </v-card-text>
        <v-card-text v-if="!loadingCargaMasiva && !cargaCargaCompleta && !errorCargaMasiva" class="text-center">
            <p class="mb-0 text-h6 text--secondary text-left">
                Actualización Masiva de Identifica de Activos
            </p>
            <v-file-input v-model="selectedUpdateFile" label="Fichero Carga .xlsx" accept=".xlsx" counter
                show-size></v-file-input>
            <a @click="descargarActualizacionMasiva">
                <v-icon color="blue">mdi-download</v-icon>Descargar Plantilla de Actualizacion Masiva de Identificador
                Activos
            </a>
            <br />
            <br />
            <v-row>
                <v-col cols="12" class="py-0">
                    <v-btn block color="blue" outlined class="my-2 mb-4" @click="cargaMasivaSolucionesPost">Cargar archivo de Actualizacion Masiva de Identificador Activos</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import moment from 'moment';
export default {
    data() {
        return {
            ficheroCarga: null,
            selectedUpdateFile: null,
            cargaMasiva: false,
            loadingCargaMasiva: false,
            ficheroCarga: null,
            cargaCargaCompleta: false,
            errorCargaMasiva: false,
            cargaLink: "",
        };
    },
    computed: {
        ...mapState({
            token: "token"
        }),
    },
    mounted() {
        this.initialize();
    },
    methods: {
        initialize() {
            this.resetValues();
        },
        resetValues() {
            this.cargaMasivaInventario = false;
            this.ficheroCarga = null;
            this.cargaCargaCompleta = false;
            this.loadingCargaMasiva = false;
            this.errorCargaMasiva = false;
            this.selectedUpdateFile = null;
        },
        cargaMasivaSolucionesPost() {
            if (this.selectedUpdateFile != null) {
                let formData = new FormData();
                formData.append("file", this.selectedUpdateFile);
                axios
                    .post(
                        "/MMCallEquipos/ActualizacionMasiva",
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    )
                    .then((response) => {
                        this.initialize();
                        this.cargaLink = response.data;
                        this.cargaCargaCompleta = true;
                    })
                    .catch((error) => {
                        this.errorCargaMasiva = true;
                        console.log(error);
                    });
            }
        },
        descargarActualizacionMasiva() {
            const url = '/MMCallEquipos/XLSXActualizacionMasiva'
            var datetime = moment().format('YYYYMMDDHHmmss');
            this.$utils.axios.downloadFile(url, 'GET', 'actualizacion-masiva-' + datetime + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        },
    },
};
</script>